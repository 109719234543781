import { FC } from "react";
import { useTranslation } from "react-i18next";
import { JobAdSalary } from ".";
import { useFormatNumber } from "./useFormatNumber";

export const isSalaryDefined = (salary: JobAdSalary | null): salary is Required<JobAdSalary> => {
  return !!salary && (!!salary.max || !!salary.min) && !!salary.currency && !!salary.period;
};

export const FormattedSalary: FC<{ salary: JobAdSalary }> = ({ salary }) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");
  const formatSalary = useFormatNumber({
    currency: salary.currency,
    style: "currency",
  });
  if (!isSalaryDefined(salary)) {
    return null;
  }
  const from = salary.min ? formatSalary(salary.min) : null;
  const to = salary.max ? formatSalary(salary.max) : null;
  const frequency = t(`job-ads.frequency-${salary.period}`);
  if (from && to) {
    return t("job-ads.salary-from-to", { from, to, frequency });
  } else {
    if (from) {
      return t("job-ads.salary-from", { from, frequency });
    }
    return t("job-ads.salary-to", { to, frequency });
  }
};
