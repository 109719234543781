import { FC } from "react";
import tw from "twin.macro";
import { JobAd } from ".";
import { Empty } from "./Empty";
import { JobAdDescriptionList } from "./JobAdDescriptionList";
import { JobAdMetaDescriptionList } from "./JobAdMetaDescriptionList";
import { JobAdContentDescriptionList } from "./JobAdContentDescriptionList";

export type JobAdDescriptionCardContentProps = {
  jobAd: JobAd;
  highlightedSkillIds?: number[];
  highlightedActivityIds?: number[];
  highlightedAccreditationIds?: number[];
  highlightedQualityIds?: string[];
  highlightedLanguageIds?: string[];
  frontApp?: string;
};

export const JobAdDescriptionCardContent: FC<JobAdDescriptionCardContentProps> = ({
  jobAd,
  highlightedSkillIds,
  highlightedActivityIds,
  highlightedAccreditationIds,
  highlightedQualityIds,
  highlightedLanguageIds,
  frontApp
}) => {
  return (
    <>

      {/* <div css={tw`text-center px-4 py-5 sm:px-6`}>
        <h3 css={tw`text-lg leading-6 font-medium text-gray-900`}>{jobAd.title || <Empty />}</h3>
        <p css={tw`mt-1 text-sm text-gray-500`}>{jobAd.jobTitle || <Empty />}</p>
      </div> */}
      <div css={tw`border-t border-gray-200 px-4 py-5 sm:px-6 space-y-8`}>
        { frontApp === "dashboard" && <JobAdMetaDescriptionList jobAd={jobAd}  /> }
        <JobAdDescriptionList
          jobAd={jobAd}
          highlightedSkillIds={highlightedSkillIds}
          highlightedActivityIds={highlightedActivityIds}
          highlightedAccreditationIds={highlightedAccreditationIds}
          highlightedQualityIds={highlightedQualityIds}
          highlightedLanguageIds={highlightedLanguageIds}
        />
        <JobAdContentDescriptionList
          jobAd={jobAd}
        />
      </div>
    </>
  );
};
