import styled from "@emotion/styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { JobAd } from ".";
import { Empty } from "./Empty";

const DescriptionTerm = styled("div")();
const DescriptionTermLabel = styled("dt")(tw`text-sm font-medium text-gray-500`);
const DescriptionTermValue = styled("dd")(tw`mt-1 text-sm text-gray-900 text-justify`);

export type JobAdContentDescriptionListProps = {
  jobAd: JobAd;
};

export const JobAdContentDescriptionList: FC<JobAdContentDescriptionListProps> = ({
  jobAd
}) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");

  return (
    <dl css={tw`grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 rounded-xl shadow-toplg2 -mx-6 py-2 px-8`}>

      <div css={tw`col-span-full`}>

        <div css={tw`flex text-xl items-center font-bold my-2`}>
          <div css={tw`flex-initial bg-[#ff3d74] transform -skew-x-12 inline-block p-1`}><span css={tw`transform skew-x-12 inline-block text-white`}>{t('job-ads.the-job')}</span></div>
          <div css={tw`flex-initial`}><span css={tw`inline-block`}> ?</span></div>
        </div>

        <DescriptionTerm css={tw`col-span-full mb-10`}>
          {/* <DescriptionTermLabel>{t("job-ads.job-description")}</DescriptionTermLabel> */}
          <DescriptionTermValue>
            {jobAd.content ? <div dangerouslySetInnerHTML={{ __html: jobAd.content.replace(/\n/g, "<br>") }} /> : <Empty />}
          </DescriptionTermValue>
        </DescriptionTerm>

        {jobAd.organizationDescription && (
          <>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial bg-[#ff3d74] transform -skew-x-12 inline-block p-1`}><span css={tw`transform skew-x-12 inline-block text-white`}>{t('job-ads.the-company')}</span></div>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}> ?</span></div>
            </div>

            <DescriptionTerm css={tw`col-span-full`}>
              {/* <DescriptionTermLabel>{t("job-ads.job-organization-description")}</DescriptionTermLabel> */}
              <DescriptionTermValue>
                {jobAd.organizationDescription ? <div dangerouslySetInnerHTML={{ __html: jobAd.organizationDescription.replace(/\n/g, "<br>") }} /> : <Empty />}
              </DescriptionTermValue>
            </DescriptionTerm>
          </>
        )}
      </div>

    </dl>
  );
};
