// @see https://formatjs.io/docs/polyfills/
// Polyfill Intl.getCanonicalLocales (required by Intl.Locale)
import "@formatjs/intl-getcanonicallocales/polyfill";
// Polyfill Intl.Locale (required by Intl.PluralRules)
import "@formatjs/intl-locale/polyfill";
// Polyfill Intl.NumberFormat
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "@formatjs/intl-numberformat/locale-data/es";
import "@formatjs/intl-numberformat/locale-data/fr";
import "@formatjs/intl-numberformat/locale-data/pt";
// Polyfill Intl.PluralRules (required by Intl.DateTimeFormat)
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/es";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-pluralrules/locale-data/pt";
import { useTranslation } from "react-i18next";

export const useFormatNumber = (options: Intl.NumberFormatOptions) => {
  const { i18n } = useTranslation();
  const formatter = new Intl.NumberFormat(i18n.language, options);
  return (value: number) => formatter.format(value);
};
