import styled from "@emotion/styled";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { Intelli7V2ScoringCriteria, JobAd } from ".";
import { Empty } from "./Empty";
import { JobAdAvailabilities } from "./JobAdAvailabilities";
import { upperFirst } from "./upperFirst";
import { SkillsImg } from './SkillsImg';

const List = styled("ul")(tw`space-y-px`, {
  "&>:first-child": tw`rounded-t-md`,
  "&>:last-child": tw`rounded-b-md`,
});
const List2 = styled("ul")(tw`space-y-px`, {
  "&>:first-child": tw`rounded-t-md`,
  "&>:last-child": tw`rounded-b-md`,
});
const InlineList = styled("ul")(tw`inline-flex space-x-px`, {
  "&>:first-child": tw`rounded-l-md`,
  "&>:last-child": tw`rounded-r-md`,
});
const ListItem = styled("li")<{ isHighlighted?: boolean }>(
  tw`relative px-4 py-2 text-sm text-gray-800 bg-white ring-1 ring-gray-200 z-0`,
  ({ isHighlighted }) => isHighlighted && tw`bg-green-100 ring-green-300 text-green-800 font-medium z-10`
);
const ListItem2 = styled("li")<{ isHighlighted?: boolean }>(
  tw`relative px-4 py-2 text-sm text-gray-800 bg-white ring-1 ring-gray-200 z-0`,
  ({ isHighlighted }) => isHighlighted && tw`bg-green-100 ring-green-300 text-green-800 font-medium z-10`
);

const DescriptionTerm = styled("div")();
const DescriptionTermLabel = styled("dt")(tw`text-sm font-medium text-gray-500`);
const DescriptionTermValue = styled("dd")(tw`mt-1 text-sm text-gray-900`);

const JobAdQualityList: FC<{ intelli7V2ScoringCriteria: Intelli7V2ScoringCriteria; highlightedQualityIds: string[] }> = ({
  intelli7V2ScoringCriteria,
  highlightedQualityIds,
}) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");
  const qualities = Object.entries(intelli7V2ScoringCriteria)
    .filter(([key, value]) => !!value)
    .map(([key]) => key);
  if (qualities.length === 0) {
    return <Empty />;
  }
  return (
    <List>
      {qualities.map((quality) => (
        <ListItem key={quality} isHighlighted={highlightedQualityIds.includes(quality)}>
          {t(`qualities.${quality}`)}
        </ListItem>
      ))}
    </List>
  );
};

export type JobAdDescriptionListProps = {
  jobAd: JobAd;
  highlightedSkillIds?: number[];
  highlightedActivityIds?: number[];
  highlightedAccreditationIds?: number[];
  highlightedQualityIds?: string[];
  highlightedLanguageIds?: string[];
};

export const JobAdDescriptionList: FC<JobAdDescriptionListProps> = ({
  jobAd,
  highlightedSkillIds = [],
  highlightedActivityIds = [],
  highlightedAccreditationIds = [],
  highlightedQualityIds = [],
  highlightedLanguageIds = [],
}) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");

  let desiredTraining = null;
  let requiredTraining = null;
  let desiredLicence = null;
  let requiredLicence = null;
  if (jobAd.source === 'poleemploi') {
    if (jobAd.formations && jobAd.formations.length > 0) {
      desiredTraining = jobAd.formations.filter(formation => formation.exigence === 'S')
      requiredTraining = jobAd.formations.filter(formation => formation.exigence === 'E')
    }
    if (jobAd.permis && jobAd.permis.length > 0) {
      desiredLicence = jobAd.permis.filter(permis => permis.exigence === 'S')
      requiredLicence = jobAd.permis.filter(permis => permis.exigence === 'E')
    }
  }
  let i7qualities: string[] = [];
  if (jobAd.intelli7V2ScoringCriteria) {
    i7qualities = Object.entries(jobAd.intelli7V2ScoringCriteria).filter(([key, value]) => !!value).map(([key]) => key);
  }

  return (
    <dl css={tw`grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 p-2`}>

      <div css={tw`col-span-full`}>
        <div css={tw`flex text-xl items-center font-bold my-2`}>
          <div css={tw`flex-initial bg-jobfirstred transform -skew-x-12 inline-block p-1 text-white`}><span css={tw`transform skew-x-12 inline-block`}>{t('job-ads.Skills')}</span></div>
          <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t('job-ads.needed')}</span></div>
        </div>

        <ul css={tw`list-none`}>
          {jobAd.skills && jobAd.skills.length > 0 && (
            <>
              {
                jobAd.skills.map((skill) => (
                  <div key={skill.id}>
                    {
                      highlightedSkillIds.includes(skill.id) ?
                        (
                          <li css={tw`my-0.5 bg-green-100 md:break-all max-w-max p-2 text-black border-solid border-4 border-jobfirstgreen font-medium rounded-3xl`} >
                            <span css={tw`flex inline-flex max-w-1rem`}><SkillsImg /></span>
                            <span css={tw`text-sm mr-2`}> {upperFirst(skill.name)}</span>
                          </li>
                        ) : (
                          <li css={tw`my-0.5 p-2`} >
                            <span css={tw`text-jobfirstred text-lg pl-1`}>&#x25cf;</span>
                            <span css={tw`text-sm text-black`}> {upperFirst(skill.name)}</span>
                          </li>
                        )
                    }
                  </div>
                ))
              }
            </>
          )}
          {/* poleemploi */}
          {jobAd.competences && jobAd.competences.length > 0 && (
            <>
              {
                jobAd.competences.map((competence) => (
                  <li key={competence.code} css={tw`my-0.5 p-2`} >
                    <span css={tw`text-jobfirstred text-lg pl-1`}>&#x25cf;</span>
                    <span css={tw`text-sm text-black`}> {upperFirst(competence.libelle)}</span>
                  </li>
                ))
              }
            </>
          )}
          {jobAd.activities && jobAd.activities.length > 0 && (
            <>
              {
                jobAd.activities.map((activity) => (
                  <div key={activity.id}>
                    {
                      highlightedActivityIds.includes(activity.id) ?
                        (
                          <li css={tw`my-0.5 bg-green-100 md:break-all max-w-max p-2 text-black border-solid border-4 border-jobfirstgreen font-medium rounded-3xl`} >
                            <span css={tw`flex inline-flex max-w-1rem`}><SkillsImg /></span>
                            <span css={tw`text-sm mr-2`}> {upperFirst(activity.name)}</span>
                          </li>
                        ) : (
                          <li css={tw`my-0.5 p-2`} >
                            <span css={tw`text-jobfirstred text-lg pl-1`}>&#x25cf;</span>
                            <span css={tw`text-sm text-black`}> {upperFirst(activity.name)}</span>
                          </li>
                        )
                    }
                  </div>
                ))
              }
            </>
          )}
          {jobAd.accreditations && jobAd.accreditations.length > 0 && (
            <>
              {
                jobAd.accreditations.map((accreditation) => (
                  <div key={accreditation.id}>
                    {
                      highlightedAccreditationIds.includes(accreditation.id) ?
                        (
                          <li css={tw`my-0.5 bg-green-100 md:break-all max-w-max p-2 text-black border-solid border-4 border-jobfirstgreen font-medium rounded-3xl`} >
                            <span css={tw`flex inline-flex max-w-1rem`}><SkillsImg /></span>
                            <span css={tw`text-sm mr-2`}> {upperFirst(accreditation.name)}</span>
                          </li>
                        ) : (
                          <li css={tw`my-0.5 p-2`} >
                            <span css={tw`text-jobfirstred text-lg pl-1`}>&#x25cf;</span>
                            <span css={tw`text-sm text-black`}> {upperFirst(accreditation.name)}</span>
                          </li>
                        )
                    }
                  </div>
                ))
              }
            </>
          )}
        </ul>
      </div>

      {/* {jobAd.skills && jobAd.skills.length > 0 && (
        <DescriptionTerm css={tw`col-span-full`}>
          <DescriptionTermLabel>{t("job-ads.main-skills")}</DescriptionTermLabel>
          <DescriptionTermValue>
            <List>
              {jobAd.skills.map((skill) => (
                <ListItem key={skill.id} isHighlighted={highlightedSkillIds.includes(skill.id)}>
                  {upperFirst(skill.name)}
                </ListItem>
              ))}
            </List>
          </DescriptionTermValue>
        </DescriptionTerm>
      )} */}

      {/* poleemploi */}
      {/* {jobAd.competences && jobAd.competences.length > 0 && (
        <DescriptionTerm css={tw`col-span-full`}>
          <DescriptionTermLabel>{t("job-ads.main-skills")}</DescriptionTermLabel>
          <DescriptionTermValue>
            <List>
              {jobAd.competences.map((competence) => (
                <ListItem key={competence.code}>
                  {upperFirst(competence.libelle)}
                </ListItem>
              ))}
            </List>
          </DescriptionTermValue>
        </DescriptionTerm>
      )} */}

      {/* {jobAd.activities && jobAd.activities.length > 0 && (
        <DescriptionTerm css={tw`col-span-full`}>
          <DescriptionTermLabel>{t("job-ads.main-activities")}</DescriptionTermLabel>
          <DescriptionTermValue>
            <List>
              {jobAd.activities.map((activity) => (
                <ListItem key={activity.id} isHighlighted={highlightedActivityIds.includes(activity.id)}>
                  {upperFirst(activity.name)}
                </ListItem>
              ))}
            </List>
          </DescriptionTermValue>
        </DescriptionTerm>
      )} */}

      {/* {jobAd.accreditations && jobAd.accreditations.length > 0 && (
        <DescriptionTerm css={tw`col-span-full`}>
          <DescriptionTermLabel>{t("job-ads.accreditations")}</DescriptionTermLabel>
          <DescriptionTermValue>
            <List>
              {jobAd.accreditations.map((accreditation) => (
                <ListItem key={accreditation.id} isHighlighted={highlightedAccreditationIds.includes(accreditation.id)}>
                  {upperFirst(accreditation.name)}
                </ListItem>
              ))}
            </List>
          </DescriptionTermValue>
        </DescriptionTerm>
      )} */}

      {i7qualities.length > 0 && (
        <div css={tw`col-span-full`}>
          <div css={tw`flex text-xl items-center font-bold my-2`}>
            <div css={tw`flex-initial bg-jobfirstred transform -skew-x-12 inline-block p-1 text-white`}><span css={tw`transform skew-x-12 inline-block`}>{t('job-ads.Qualities')} </span></div>
            <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t('job-ads.needed')}</span></div>
          </div>

          <ul css={tw`list-none`}>
            <>
              {
                i7qualities.map((i7quality, index) => (
                  <div key={`${jobAd.uuid}-criteria-${index}`}>
                    {
                      highlightedQualityIds.includes(i7quality) ?
                        (
                          <li css={tw`my-0.5 bg-green-100 md:break-all max-w-max p-2 text-black border-solid border-4 border-jobfirstgreen font-medium rounded-3xl`} >
                            <span css={tw`flex inline-flex max-w-1rem`}><SkillsImg /></span>
                            <span css={tw`text-sm mr-2`}> {t(`qualities.${i7quality}`)}</span>
                          </li>
                        ) : (
                          <li css={tw`my-0.5 p-2`} >
                            <span css={tw`text-jobfirstred text-xl pl-1`}>&#x25cf;</span>
                            <span css={tw`text-sm text-black`}> {t(`qualities.${i7quality}`)}</span>
                          </li>
                        )
                    }
                  </div>
                ))
              }
            </>
          </ul>
        </div>
      )}

      {/* Pole Emploi */}
      {jobAd.qualites && jobAd.qualites.length > 0 && (
        <div css={tw`col-span-full`}>
          <div css={tw`flex text-xl items-center font-bold my-2`}>
            <div css={tw`flex-initial bg-jobfirstred transform -skew-x-12 inline-block p-1 text-white`}><span css={tw`transform skew-x-12 inline-block`}>{t('job-ads.Qualities')} </span></div>
            <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t('job-ads.needed')}</span></div>
          </div>
          <ul css={tw`list-none`}>
            {
              jobAd.qualites.map((qualite) => (
                <div key={qualite.libelle}>
                  {
                    <li css={tw`my-0.5 p-2`} >
                      <span css={tw`text-jobfirstred text-xl pl-0.5`}>&#x25cf;</span>
                      <span css={tw`text-sm text-black`}> {upperFirst(qualite.libelle)}</span>
                    </li>
                  }
                </div>
              ))
            }
          </ul>
        </div>
      )}
      {/* {jobAd.intelli7V2ScoringCriteria && (
          <DescriptionTerm css={tw`col-span-full`}>
            <DescriptionTermLabel>{t("job-ads.qualities")}</DescriptionTermLabel>
            <DescriptionTermValue>
              {jobAd.intelli7V2ScoringCriteria ? (
                <JobAdQualityList
                  intelli7V2ScoringCriteria={jobAd.intelli7V2ScoringCriteria}
                  highlightedQualityIds={highlightedQualityIds}
                />
              ) : (
                <Empty />
              )}
            </DescriptionTermValue>
          </DescriptionTerm>
        )}

        {jobAd.qualites && jobAd.qualites.length > 0 && (
          <DescriptionTerm css={tw`col-span-full`}>
            <DescriptionTermLabel>{t("job-ads.qualities")}</DescriptionTermLabel>
            <DescriptionTermValue>
              <List>
                {jobAd.qualites.map((qualite) => (
                  <ListItem key={qualite.libelle}>
                    {upperFirst(qualite.libelle)}
                  </ListItem>
                ))}
              </List>
            </DescriptionTermValue>
          </DescriptionTerm>
        )}*/}

      {/* poleemploi */}
      {
        requiredTraining && requiredTraining.length > 0 && (

          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.required-training")}</span></div>
            </div>

            <ul css={tw`list-none`}>
              {
                requiredTraining.map((training) => (
                  <div key={training.code}>
                    {
                      <li css={tw`my-0.5 p-2`} >
                        <span css={tw`text-jobfirstred text-xl pl-0.5`}>&#x25cf;</span>
                        <span css={tw`text-sm text-black`}> {`${upperFirst(training.niveauLibelle)} (${upperFirst(training.domainLibelle)})`}</span>
                      </li>
                    }
                  </div>
                ))
              }
            </ul>
          </div>
          // <DescriptionTerm css={tw`col-span-full`}>
          //   <DescriptionTermLabel>{t("job-ads.required-training")}</DescriptionTermLabel>
          //   <DescriptionTermValue>
          //     <List>
          //       {requiredTraining.map((training) => (
          //         <ListItem key={training.code}>
          //           {`${upperFirst(training.niveauLibelle)} (${upperFirst(training.domainLibelle)})`}
          //         </ListItem>
          //       ))}
          //     </List>
          //   </DescriptionTermValue>
          // </DescriptionTerm>
        )
      }

      {/* poleemploi */}
      {

        desiredTraining && desiredTraining.length > 0 && (

          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.desired-training")}</span></div>
            </div>

            <ul css={tw`list-none`}>
              {
                desiredTraining.map((training) => (
                  <div key={training.code}>
                    {
                      <li css={tw`my-0.5 p-2`} >
                        <span css={tw`text-jobfirstred text-xl pl-0.5`}>&#x25cf;</span>
                        <span css={tw`text-sm text-black`}> {`${upperFirst(training.niveauLibelle)} (${upperFirst(training.domainLibelle)})`}</span>
                      </li>
                    }
                  </div>
                ))
              }
            </ul>
          </div>
          // <DescriptionTerm css={tw`col-span-full`}>
          //   <DescriptionTermLabel>{t("job-ads.desired-training")}</DescriptionTermLabel>
          //   <DescriptionTermValue>
          //     <List>
          //       {desiredTraining.map((training) => (
          //         <ListItem key={training.code}>
          //           {`${upperFirst(training.niveauLibelle)} (${upperFirst(training.domainLibelle)})`}
          //         </ListItem>
          //       ))}
          //     </List>
          //   </DescriptionTermValue>
          // </DescriptionTerm>
        )
      }

      {/* poleemploi */}
      {
        requiredLicence && requiredLicence.length > 0 && (

          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.required-licence")}</span></div>
            </div>

            <ul css={tw`list-none`}>
              {
                requiredLicence.map((licence, index) => (
                  <div key={index}>
                    {
                      <li css={tw`my-0.5 p-2`} >
                        <span css={tw`text-jobfirstred text-xl pl-0.5`}>&#x25cf;</span>
                        <span css={tw`text-sm text-black`}> {`${upperFirst(licence.libelle)}`}</span>
                      </li>
                    }
                  </div>
                ))
              }
            </ul>
          </div>
          // <DescriptionTerm css={tw`col-span-full`}>
          //   <DescriptionTermLabel>{t("job-ads.required-licence")}</DescriptionTermLabel>
          //   <DescriptionTermValue>
          //     <List>
          //       {requiredLicence.map((licence, index) => (
          //         <ListItem key={index}>
          //           {`${upperFirst(licence.libelle)}`}
          //         </ListItem>
          //       ))}
          //     </List>
          //   </DescriptionTermValue>
          // </DescriptionTerm>
        )
      }

      {/* poleemploi */}
      {
        desiredLicence && desiredLicence.length > 0 && (

          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.desired-licence")}</span></div>
            </div>

            <ul css={tw`list-none`}>
              {
                desiredLicence.map((licence, index) => (
                  <div key={index}>
                    {
                      <li css={tw`my-0.5 p-2`} >
                        <span css={tw`text-jobfirstred text-xl pl-0.5`}>&#x25cf;</span>
                        <span css={tw`text-sm text-black`}> {`${upperFirst(licence.libelle)}`}</span>
                      </li>
                    }
                  </div>
                ))
              }
            </ul>
          </div>
          // <DescriptionTerm css={tw`col-span-full`}>
          //   <DescriptionTermLabel>{t("job-ads.desired-licence")}</DescriptionTermLabel>
          //   <DescriptionTermValue>
          //     <List>
          //       {desiredLicence.map((licence, index) => (
          //         <ListItem key={index}>
          //           {`${upperFirst(licence.libelle)}`}
          //         </ListItem>
          //       ))}
          //     </List>
          //   </DescriptionTermValue>
          // </DescriptionTerm>
        )
      }

      {
        jobAd.languages && jobAd.languages.length > 0 && (
          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.languages")}</span></div>
            </div>
            {/* <ul css={tw`list-none`}> */}
            {
              jobAd.languages.map((language) => (
                <div key={language} css={tw`inline-block`}>
                  {
                    highlightedLanguageIds.includes(language.toLowerCase()) ?
                      (
                        <div css={tw`my-0.5 bg-green-100 md:break-all max-w-max p-2 text-black border-solid border-4 border-jobfirstgreen font-medium rounded-3xl`} >
                          <span css={tw`flex inline-flex max-w-1rem`}><SkillsImg /></span>
                          <span css={tw`text-sm mr-2`}> {t(`languages.${language.toLowerCase()}`)}</span>
                        </div>
                      ) : (
                        <div css={tw`my-0.5 p-2`} >
                          <span css={tw`text-jobfirstred text-xl pl-1`}>&#x25cf;</span>
                          <span css={tw`text-sm text-black`}> {t(`languages.${language.toLowerCase()}`)}</span>
                        </div>
                      )
                  }
                </div>
              ))
            }
            {/* </ul> */}
          </div>
          // <DescriptionTerm css={tw`col-span-full`}>
          //   <DescriptionTermLabel>{t("job-ads.required-languages")}</DescriptionTermLabel>
          //   <DescriptionTermValue>
          //     <InlineList>
          //       {jobAd.languages.map((language) => (
          //         <ListItem key={language} isHighlighted={highlightedLanguageIds.includes(language.toLowerCase())}>
          //           {t(`languages.${language.toLowerCase()}`)}
          //         </ListItem>
          //       ))}
          //     </InlineList>
          //   </DescriptionTermValue>
          // </DescriptionTerm>
        )
      }

      {
        jobAd.availabilities && (
          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.schedules")}</span></div>
            </div>
            <DescriptionTerm css={tw`col-span-full`}>
              <DescriptionTermValue>
                <JobAdAvailabilities availabilities={jobAd.availabilities} />
              </DescriptionTermValue>
            </DescriptionTerm>
          </div>
        )
      }

      {
        jobAd.remuneration && jobAd.remuneration.benefits && (
          <div css={tw`col-span-full`}>
            <div css={tw`flex text-xl items-center font-bold my-2`}>
              <div css={tw`flex-initial`}><span css={tw`inline-block`}>{t("job-ads.job-benefits")}</span></div>
            </div>
            <DescriptionTerm css={tw`col-span-full`}>
              <DescriptionTermValue>
                {jobAd.remuneration && jobAd.remuneration.benefits ? <div dangerouslySetInnerHTML={{ __html: jobAd.remuneration.benefits.replace(/\n/g, "<br>") }} /> : <Empty />}
              </DescriptionTermValue>
            </DescriptionTerm>
          </div>
        )
      }

    </dl >
  );
};
