import { FC } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { Availabilities, WeekDay } from ".";

const days: WeekDay[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

export const JobAdAvailabilities: FC<{
  availabilities: Availabilities;
}> = ({ availabilities }) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");
  return (
    <table>
      <tbody>
        {days
          .filter((day) => {
            // Hide days without required availability
            const dayAvailability = availabilities[day];
            return dayAvailability.morning || dayAvailability.afternoon || dayAvailability.evening;
          })
          .map((day) => {
            const dayAvailability = availabilities[day];
            const dayAvailabilityPeriods = [
              dayAvailability.morning && t("availabilities.morning"),
              dayAvailability.afternoon && t("availabilities.afternoon"),
              dayAvailability.evening && t("availabilities.evening"),
            ]
              .filter(Boolean)
              .join(" – ");
            return (
              <tr key={day}>
                <th css={tw`text-left font-medium text-gray-800`}>{t(`availabilities.${day}`)}</th>
                <td css={tw`pl-4`}>{dayAvailabilityPeriods}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};
