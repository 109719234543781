// import styled from "@emotion/styled";
// import {
//   AcademicCapIcon,
//   BriefcaseIcon,
//   CalendarIcon,
//   CurrencyEuroIcon,
//   LocationMarkerIcon,
// } from "@heroicons/react/solid";
import { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import { ContratImg } from './ContratImg';
import { EntrepriseImg } from './EntrepriseImg';
import { ExperienceImg } from './ExperienceImg';
import { LocationImg } from './LocationImg';
import { SalaireImg } from './SalaireImg';
import { StartImg } from './StartImg';
import { TempsImg } from './TempsImg';
import { JobAd } from ".";
import { FormattedSalary, isSalaryDefined } from "./JobAdSalary";

// const DescriptionList = styled("dl")(tw`flex flex-col justify-center gap-y-2 sm:(flex-row flex-wrap gap-x-6)`);
// const DescriptionTerm = styled("div")(tw`flex items-center`);
// const DescriptionTermIcon = styled("svg")(tw`flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400`);
// DescriptionTermIcon.defaultProps = {
//   "aria-hidden": true,
// };
// const DescriptionTermLabel = styled("dt")(tw`sr-only`);
// const DescriptionTermValue = styled("dd")(tw`text-sm text-gray-500`);


// const JobAdCardListDoubleItem: FC<{ items: String, jobAd: JobAd } & HTMLAttributes<HTMLUListElement>> = ({ items, jobAd }) => {

//   return (
//     <>
//       {items.indexOf("-") > -1 && (
//         <div css={tw`flex w-11/12`}>
//           { items.split("-").map((item, index) => (
//             <JobAdCardListItem icon={`../src/${item}.svg`} text={jobAd.organizationName} color="black" bold={true} />
//           ))}
//         </div>
//       )}
//     </>
//   )
// }

const JobAdCardListItem = ({ icon = "", text = "", color = "gray", bold = false }) => {
  return (
    <div css={tw`grid col-span-1 grid-cols-6 gap-4 bg-white items-center w-full rounded-2xl p-2`}>
      <div css={tw`grid col-span-1`}>
        {icon === "contrat" && <ContratImg />}
        {icon === "entreprise" && <EntrepriseImg />}
        {icon === "experience" && <ExperienceImg />}
        {icon === "location" && <LocationImg />}
        {icon === "salaire" && <SalaireImg />}
        {icon === "start" && <StartImg />}
        {icon === "temps" && <TempsImg />}
      </div>
      <div
        css={[
          tw`grid col-span-5 text-left`,
          color === "black" ? tw`text-black` : tw`text-gray-500`,
          bold && tw`font-semibold`
        ]}>
        {text}
      </div>
    </div>
  )
}


export const JobAdMetaDescriptionList: FC<{ jobAd: JobAd } & HTMLAttributes<HTMLUListElement>> = ({
  jobAd,
  ...props
}) => {
  const { t } = useTranslation("@jobook-io/jobook-job-card");
  let startYear = null, startMonth = null, startDay = null;
  if (jobAd.startDate !== null) [startYear, startMonth, startDay] = jobAd.startDate.split('-');
  let salaryText = "";
  if (isSalaryDefined(jobAd.remuneration)) {
    let frequencyText = ""
    if (jobAd.remuneration.period) {
      frequencyText = t(`job-ads.frequency-${jobAd.remuneration.period}`);
    }
    if(jobAd.remuneration.min && jobAd.remuneration.max) {
      if (jobAd.remuneration.min === jobAd.remuneration.max) {
        salaryText = t(`job-ads.salary-from`, { from: `${jobAd.remuneration.min} ${jobAd.remuneration.currency}`, frequency: frequencyText})
      } else {
        salaryText = t(`job-ads.salary-from-to`, { from: `${jobAd.remuneration.min} ${jobAd.remuneration.currency}`, to: `${jobAd.remuneration.max} ${jobAd.remuneration.currency}`, frequency: frequencyText})
      }
    } else if (jobAd.remuneration.min && !jobAd.remuneration.max) {
      salaryText = t(`job-ads.salary-from`, { from: `${jobAd.remuneration.min} ${jobAd.remuneration.currency}`, frequency: frequencyText})
    } else if (!jobAd.remuneration.min && jobAd.remuneration.max) {
      salaryText = t(`job-ads.salary-to`, { to: `${jobAd.remuneration.max} ${jobAd.remuneration.currency}`, frequency: frequencyText})
    }
  }
  let contractText = "CDI"
  if (jobAd.contract) {
    contractText = t(`contract-types.${jobAd.contract}`);
    if (["CDD", "INTERIM", "INTERN", "FREELANCER", "APPRENTICE"].includes(jobAd.contract)) {
      contractText += " (" + t("job-ads.contract-duration-in-months", { count: jobAd.durationInMonth }) + ")"
    }
  }

  return (
    <dl css={tw`bg-[#667af8] grid grid-cols-2 gap-2 rounded-xl shadow-lg p-2 items-center text-center `}>

      {/* <header css={tw`absolute top-1/2 left-0 right-0 px-6 py-5 flex flex-col items-center text-center transform -translate-y-1/2`}> */}

      {jobAd.organizationName && (
        <JobAdCardListItem icon="entreprise" text={jobAd.organizationName} color="black" bold={true} />
      )}
      {jobAd.location && jobAd.location.address && (
        <JobAdCardListItem icon="location" text={t(`job-ads.at-location`, { address: jobAd.location.address })} color="black" bold={true} />
      )}
      {jobAd.contract && (
        <JobAdCardListItem icon="contrat" text={contractText} color="black" bold={true} />
      )}
      {isSalaryDefined(jobAd.remuneration) && (
        <JobAdCardListItem icon="salaire" text={salaryText} color="black" bold={true} />
      )}
      {jobAd.employmentType && (
        <JobAdCardListItem icon="temps" text={t(`employment-types.${jobAd.employmentType}`)} color="black" bold={true} />
      )}
      {jobAd.yearsOfExperience !== null && (
        <JobAdCardListItem icon="experience" text={jobAd.yearsOfExperience === 0
          ? t("job-ads.zero-years-of-experience")
          : t("job-ads.years-of-experience-value", { count: jobAd.yearsOfExperience })
        } color="black" bold={true} />
      )}
      {jobAd.startDate !== null && (
        <JobAdCardListItem icon="start" text={`${startDay}/${startMonth}/${startYear}`} color="black" bold={true} />
      )}
      {/* <JobAdCardListItem icon="entreprise" text={jobAd.organizationName} color="black" bold={true} /> */}
      {/* <div css={tw`flex w-11/12`}>
        <JobAdCardListItem icon="../src/entreprise.svg" text={jobAd.organizationName} color="black" bold={true} />
        <JobAdCardListItem icon={locationSvg} text={t(`job-ads.at-location`, { address: jobAd?.location?.address })} bold={true} />
      </div>
      <div css={tw`flex w-11/12`}>
        <JobAdCardListItem icon="./entreprise.svg" text="toto" />
        <JobAdCardListItem icon="./entreprise.svg" text="toto" />
      </div>
      <div css={tw`flex w-11/12`}>
        <JobAdCardListItem icon="./entreprise.svg" text="toto" />
        <JobAdCardListItem icon="./entreprise.svg" text="toto" />
      </div>
      <div css={tw`flex w-11/12`}>
        <JobAdCardListItem icon="./entreprise.svg" text="toto" />
      </div> */}
      {/* </header> */}
    </dl>
    // <DescriptionList {...props}>
    //   {isSalaryDefined(jobAd.remuneration) && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={CurrencyEuroIcon} />
    //       <DescriptionTermLabel>{t("job-ads.salary")}</DescriptionTermLabel>
    //       <DescriptionTermValue>
    //         <FormattedSalary salary={jobAd.remuneration} />
    //       </DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}

    //   {jobAd.contract && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={BriefcaseIcon} />
    //       <DescriptionTermLabel>{t("job-ads.contract")}</DescriptionTermLabel>
    //       <DescriptionTermValue>
    //         {t(`contract-types.${jobAd.contract}`)}
    //         {["CDD", "INTERIM", "INTERN", "FREELANCER", "APPRENTICE"].includes(jobAd.contract) && (
    //           <span>
    //             {" "}
    //             (
    //             {t("job-ads.contract-duration-in-months", {
    //               count: jobAd.durationInMonth,
    //             })}
    //             )
    //           </span>
    //         )}
    //       </DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}

    //   {jobAd.employmentType && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={CalendarIcon} />
    //       <DescriptionTermLabel>{t("job-ads.employment-type")}</DescriptionTermLabel>
    //       <DescriptionTermValue>{t(`employment-types.${jobAd.employmentType}`)}</DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}

    //   {jobAd.location && jobAd.location.address && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={LocationMarkerIcon} />
    //       <DescriptionTermLabel>{t("job-ads.location")}</DescriptionTermLabel>
    //       <DescriptionTermValue>{jobAd.location.address}</DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}

    //   {jobAd.yearsOfExperience !== null && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={AcademicCapIcon} />
    //       <DescriptionTermLabel>{t("job-ads.years-of-experience")}</DescriptionTermLabel>
    //       <DescriptionTermValue>
    //       {jobAd.yearsOfExperience === 0
    //           ? t("job-ads.zero-years-of-experience")
    //           : t("job-ads.years-of-experience-value", {
    //               count: jobAd.yearsOfExperience,
    //             })}
    //       </DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}

    //   {jobAd.startDate !== null && (
    //     <DescriptionTerm>
    //       <DescriptionTermIcon as={CalendarIcon} />
    //       <DescriptionTermLabel>{t("job-ads.start-date")}</DescriptionTermLabel>
    //       <DescriptionTermValue>
    //         {t("job-ads.start-date")}
    //         {`${startDay}/${startMonth}/${startYear}`}
    //       </DescriptionTermValue>
    //     </DescriptionTerm>
    //   )}
    // </DescriptionList>
  );
};
